import React from "react";
import PropTypes from "prop-types";

export const ProfileIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M18.5 20.25H14.5C14.09 20.25 13.75 19.91 13.75 19.5C13.75 19.09 14.09 18.75 14.5 18.75H18.5C18.91 18.75 19.25 19.09 19.25 19.5C19.25 19.91 18.91 20.25 18.5 20.25Z"
          fill="currentColor"
        />
        <path
          d="M16.5 22.25C16.09 22.25 15.75 21.91 15.75 21.5V17.5C15.75 17.09 16.09 16.75 16.5 16.75C16.91 16.75 17.25 17.09 17.25 17.5V21.5C17.25 21.91 16.91 22.25 16.5 22.25Z"
          fill="currentColor"
        />
        <path
          d="M12.159 11.62H12.079C12.0194 11.61 11.9586 11.61 11.899 11.62C8.99897 11.53 6.80897 9.25 6.80897 6.44C6.79897 5.06 7.33897 3.76 8.31897 2.78C8.79912 2.29493 9.37072 1.90994 10.0007 1.64731C10.6306 1.38468 11.3065 1.24964 11.989 1.25C14.849 1.25 17.179 3.58 17.179 6.44C17.179 9.25 14.989 11.52 12.189 11.62H12.159ZM11.989 2.75C10.999 2.75 10.079 3.14 9.37897 3.83C8.68897 4.53 8.30897 5.45 8.30897 6.43C8.30897 8.43 9.86897 10.05 11.859 10.11C11.919 10.1 12.049 10.1 12.179 10.11C14.149 10.02 15.679 8.41 15.679 6.43C15.679 4.41 14.019 2.75 11.989 2.75Z"
          fill="currentColor"
        />
        <path
          d="M11.9899 22.5601C9.94991 22.5601 8.01991 22.0301 6.55991 21.0501C5.16991 20.1201 4.40991 18.8501 4.40991 17.4801C4.40991 16.1101 5.17991 14.8501 6.55991 13.9301C9.54991 11.9301 14.4099 11.9301 17.3999 13.9301C17.7399 14.1601 17.8399 14.6301 17.6099 14.9701C17.3799 15.3201 16.9099 15.4101 16.5699 15.1801C14.0799 13.5201 9.87991 13.5201 7.38991 15.1801C6.42991 15.8201 5.90991 16.6301 5.90991 17.4801C5.90991 18.3301 6.42991 19.1601 7.38991 19.8001C8.59991 20.6101 10.2299 21.0501 11.9799 21.0501C12.0791 21.0494 12.1774 21.0683 12.2692 21.1056C12.3611 21.1429 12.4447 21.198 12.5152 21.2677C12.5858 21.3373 12.642 21.4202 12.6805 21.5115C12.7191 21.6029 12.7393 21.7009 12.7399 21.8001C12.7406 21.8992 12.7217 21.9975 12.6844 22.0894C12.647 22.1812 12.592 22.2648 12.5223 22.3354C12.4527 22.406 12.3698 22.4621 12.2785 22.5007C12.1871 22.5392 12.0891 22.5594 11.9899 22.5601Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

ProfileIcon.propTypes = {
  props: PropTypes.any,
};

