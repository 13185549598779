import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { allProduct } from "../../../../common/components/product/allProduct";
import "../components/storeItem/storeItem.css";
import StoreProducts from "../components/storeItem/StoreProducts";
import { StoreItemImage } from "./StoreItemImage";
import { ProductDetails } from "./ProductDetail";

const Product = () => {
  const { id } = useParams();
  const [state, setState] = useState({
    selectedColor: 0,
    quantity: 1,
  });
  const [cart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    const data = allProduct.filter(
      (val) => val.name === id.replaceAll("_", " ")
    );
    setData(data);
  }, [id]);

  const item = useMemo(
    () => data[state.selectedColor] || null,
    [state.selectedColor,data])

  useEffect(() => {
    const updatedCart = totalQuantity.filter((val) => val.quantity > 0);
    setCart(updatedCart);
    localStorage.setItem("user-Item", JSON.stringify(updatedCart));
  }, [totalQuantity]);

  const handleNextImage = () => {
    setState((prevState) => ({
      ...prevState,
      selectedColor: Math.min(prevState.selectedColor + 1, data.length - 1),
      quantity: data[prevState.selectedColor + 1]?.quantity || 0,
    }));
  };

  const handlePreviousImage = () => {
    setState((prevState) => ({
      ...prevState,
      selectedColor: Math.max(prevState.selectedColor - 1, 0),
      quantity: data[prevState.selectedColor - 1]?.quantity || 0,
    }));
  };

  const handleChange = (newQuantity) => {
    if (newQuantity >= 0) {
      const updatedData = [...data];
      updatedData[state.selectedColor].quantity = Number(newQuantity);

      setState((prevState) => ({
        ...prevState,
        quantity: Number(newQuantity),
      }));

      setTotalQuantity(updatedData);
    }
  };

  return (
    <Fragment>
      <div className="storeItem-page__inner !mt-16 !text-[#D7D7D7]">
        {item && (
          <>
            <StoreItemImage
              setState={setState}
              color={data.map((val) => val.colors)}
              item={item}
              selectedColor={state.selectedColor}
              handlePreviousImage={handlePreviousImage}
              handleNextImage={handleNextImage}
            />
            <ProductDetails
              item={item}
              quantity={state.quantity}
              onQuantityChange={handleChange}
            />
          </>
        )}
      </div>
      <div className="flex flex-col mt-12 lg:!px-20">
        <h2 className="w-full text-center md:my-[40px] my-3 font-light md:text-4xl text-2xl text-white">
          You May Also Like
        </h2>
        <StoreProducts />
      </div>
    </Fragment>
  );
};

export default Product;
