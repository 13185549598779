import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axaoIcon from "../../assets/icons/axaoIcon.svg";
import logoutIcon from "../../assets/icons/logoutIcon.svg";
import { logoutUser } from "../../redux/actions/authAction";
import arrow from "../../assets/icons/acc_arrow.svg";
import flare from "../../assets/icons/flare.svg";
import start from "../../assets/icons/started.svg";
import link from "../../assets/icons/link.svg";
import security from "../../assets/icons/security.svg";
import profile from "../../assets/icons/profile.svg";
import connections from "../../assets/icons/connections.svg";
import { StartedIcon } from "../../assets/icons/started"
import { ConnectionsIcon } from "../../assets/icons/connections"
import { ProfileIcon } from "../../assets/icons/profile"
import { SecurityIcon } from "../../assets/icons/security"
import { LinkIcon } from "../../assets/icons/link"
import { LogoutIcon } from "../../assets/icons/logout"
import Tooltip from "@mui/material/Tooltip";
import { useStateContext } from "../../libs/context";

const getStartedRoutes = [
  "/activateaccount",
  "/activateaccount/login",
  "/activateaccount/resetpassword",
  "/activateaccount/updatepassword",
];

const routes = [
  {
    path: "/activateaccount/profilepicture",
    title: "Profile Details",
  },
  {
    path: "/activateaccount/aboutme",
    title: "Add Links",
  },
  {
    path: "/activateaccount/connections",
    title: "Connections",
  },
  {
    path: "/activateaccount/security",
    title: "Security",
  },
];

const mobile = [
  {
    path:
      "/activateaccount" ||
      "/activateaccount/login" ||
      "/activateaccount/resetpassword" ||
      "/activateaccount/updatepassword",
    title: "Get Started",
    icon: <StartedIcon />,
  },
  {
    path: "/activateaccount/profilepicture",
    title: "Profile Details",
    icon: <ProfileIcon />,
  },
  {
    path: "/activateaccount/aboutme",
    title: "Add Links",
    icon: <LinkIcon />,
  },
  {
    path: "/activateaccount/connections",
    title: "Connections",
    icon: <ConnectionsIcon />,
  },
  {
    path: "/activateaccount/security",
    title: "Security",
    icon: <SecurityIcon />,
  },
];
const Sidebar = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { authentication, setAuthentication } = useStateContext()
  // const dispatch = useDispatch();
  // const user = useSelector((state) => state.auth.user);
  // const handleLogout = () => dispatch(logoutUser());
  const handleLogout = () => setAuthentication(false);

  return (
    <div className="w-full">
      <div className="max-md:hidden w-[227px] min-h-screen fixed border-r-[0.5px] border-solid border-slate-50/15">
        <img
          className="mt-8 pl-14"
          onClick={() => navigate("/")}
          src={axaoIcon}
          alt=""
        />
        {/* {authentication && (
          <div onClick={handleLogout} className="layout__card-logout">
            <img src={logoutIcon} alt="" />
          </div>
        )} */}

        <div className="flex flex-col mt-24 gap-10">
         {!authentication && (<NavLink
            to="/activateaccount"
            className={`relative pl-14 ${
              getStartedRoutes.includes(pathname)
                ? "text-white"
                : "text-white/40"
            }`}
          >
            {getStartedRoutes.includes(pathname) && (
              <img
                src={arrow}
                alt="arrow"
                className="absolute left-0 -top-12"
              />
            )}
            Get Started
          </NavLink>)}

          {routes.map((paths, i) => {
            const { path, title } = paths;
            return (
              <NavLink
                to={path}
                key={i}
                style={{
                  position: "relative",
                  paddingLeft: "3.5rem",
                }}
                className={`${pathname === path && "text-white"}`}
              >
                {pathname === path && (
                  <img
                    src={arrow}
                    alt="arrow"
                    className="absolute left-0 -top-12"
                  />
                )}
                {title}
              </NavLink>
            );
          })}
        </div>
      </div>

      {/* mobile navigation */}
      <div className="w-full hidden max-md:flex bg-black text-white z-50 fixed top-0 py-8 px-8 border-b-[1px] border-solid border-gray-50/30 justify-between items-center">
        <div>
          <img
            className=""
            onClick={() => navigate("/")}
            src={axaoIcon}
            alt=""
          />
        </div>

        <div className="flex flex-col justify-center gap-1 cursor-pointer">
          <small className="w-4 h-0.5 bg-white" />
          <small className="w-8 h-0.5 bg-white" />
          <small className="w-8 h-0.5 bg-white" />
        </div>
      </div>

      {/* bottom mobile nav */}
      <div className="hidden overflow-hidden max-sm:flex z-50 w-full py-4 px-4 justify-between items-center fixed bottom-0 border-t-[0.5px] border-slate-50/25 border-solid bg-black">
        {mobile.map((paths, i) => {
          const { path, title, icon } = paths;
          return (
            <Tooltip title={title} key={i} arrow>
              <NavLink
                to={path}
                key={i}
                className={`${pathname === path && "text-white"} ${i===0 && authentication && 'hidden' }`}
              >
                <div className="relative">
                  {icon}
                  {path === pathname && (
                    <div className="w-24 h-32 absolute -left-8 -top-1">
                      <img src={flare} alt="icon" className="object-cover" />
                    </div>
                  )}
                </div>
              </NavLink>
             </Tooltip>
          );
        })}
        {authentication && (
          <div onClick={handleLogout} className="layout__card-logout hover:text-white">
            <LogoutIcon />
          </div>
        )}
      </div>
    </div>
  );
};

// const Container = styled.div`
//   z-index: 100;
//   width: 100%;
//   height: 145px;
//   position: fixed;
//   left: 0;
//   top: 0;
//   margin-top: 0;
//   background-color: #000000;
//   /* padding-left: 55px; */
//   padding-top: 31px;
//   .layout__card-logo {
//     margin-left: 20px;
//   }

//   .layout__card-logout {
//     position: absolute;
//     right: 20px;
//     top: 31px;
//     cursor: pointer;
//   }

//   @media screen and (min-width: 768px) {
//     width: 227px;
//     height: 100vh;
//     padding-left: 55px;
//     background-color: #000;
//     border-right-width:0.4px;
//     border-color:rgba(255,255,255, 0.07);
//     border-style:solid;
//     .layout__card-logo {
//       margin-left: 0px;
//     }
//     .layout__card-logout {
//       display: none;
//     }
//   }
// `;

// const Content = styled.div`
//   display: flex;
//   align-items: center;
//   height: 55px;
//   margin-top: 40px;
//   background-color: inherit;
//   padding-left: 20px;
//   overflow-x: scroll;
//   width: 100%;

//   ::-webkit-scrollbar {
//     display: none;
//   }

//   -ms-overflow-style: none;
//   scrollbar-width: none;

//   a {
//     font-size: 15px;
//     line-height: 22px;
//     letter-spacing: -0.04em;
//     text-transform: capitalize;
//     color: var(--lightTextColor);
//     position:absolute;
//     left:0;
//     display: block;
//     margin-right: 20px;
//     white-space: nowrap;
//     width:100%
//   }

//   .active {
//     color: var(--whiteColor);
//     border-bottom: 3px solid #ffffff;
//     padding-bottom: 13px;
//     margin-top: 16px;
//   }

//   @media screen and (min-width: 380px) {
//     a {
//       margin-right: 30px;
//     }
//   }

//   @media screen and (min-width: 768px) {
//     margin-top: 100px;
//     flex-direction: column;
//     align-items: flex-start;
//     padding-left: 0px;
//     height: auto;
//     a {
//       font-size: 17px;
//       margin-bottom: 30px;
//     }

//     .active {
//       border: none;
//       padding-bottom: 0px;
//       margin-top: 0px;
//     }
//   }
// `;

export default Sidebar;
