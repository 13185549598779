import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./footer";
import useScrollToTop from "../../utils/useScrollToTop";
import MobileSidebar from "./sidebar";
import ChatIcon from "../../assets/icons/chatIcon";

const Main = () => {
  useScrollToTop();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <Header {...{ setSidebarOpen }} />
      <Outlet />
      <footer className="relative w-full">
        <Footer />
        <div className="size-12 rounded-full bg-white/20 absolute right-16 bottom-16 cursor-pointer flex justify-center items-center ">
          <ChatIcon className="z-50 text-white" />
        </div>
      </footer>
      {sidebarOpen && <MobileSidebar {...{ setSidebarOpen }} />}
    </>
  );
};

export default Main;
