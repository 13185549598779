import React from "react";
import PropTypes from "prop-types";

export function StartedIcon(props) {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9.75H4C1.58 9.75 0.25 8.42 0.25 6V4C0.25 1.58 1.58 0.25 4 0.25H6C8.42 0.25 9.75 1.58 9.75 4V6C9.75 8.42 8.42 9.75 6 9.75ZM4 1.75C2.42 1.75 1.75 2.42 1.75 4V6C1.75 7.58 2.42 8.25 4 8.25H6C7.58 8.25 8.25 7.58 8.25 6V4C8.25 2.42 7.58 1.75 6 1.75H4Z"
        fill="currentColor"
      />
      <path
        d="M18 9.75H16C13.58 9.75 12.25 8.42 12.25 6V4C12.25 1.58 13.58 0.25 16 0.25H18C20.42 0.25 21.75 1.58 21.75 4V6C21.75 8.42 20.42 9.75 18 9.75ZM16 1.75C14.42 1.75 13.75 2.42 13.75 4V6C13.75 7.58 14.42 8.25 16 8.25H18C19.58 8.25 20.25 7.58 20.25 6V4C20.25 2.42 19.58 1.75 18 1.75H16Z"
        fill="currentColor"
      />
      <path
        d="M18 21.75H16C13.58 21.75 12.25 20.42 12.25 18V16C12.25 13.58 13.58 12.25 16 12.25H18C20.42 12.25 21.75 13.58 21.75 16V18C21.75 20.42 20.42 21.75 18 21.75ZM16 13.75C14.42 13.75 13.75 14.42 13.75 16V18C13.75 19.58 14.42 20.25 16 20.25H18C19.58 20.25 20.25 19.58 20.25 18V16C20.25 14.42 19.58 13.75 18 13.75H16Z"
        fill="currentColor"
      />
      <path
        d="M6 21.75H4C1.58 21.75 0.25 20.42 0.25 18V16C0.25 13.58 1.58 12.25 4 12.25H6C8.42 12.25 9.75 13.58 9.75 16V18C9.75 20.42 8.42 21.75 6 21.75ZM4 13.75C2.42 13.75 1.75 14.42 1.75 16V18C1.75 19.58 2.42 20.25 4 20.25H6C7.58 20.25 8.25 19.58 8.25 18V16C8.25 14.42 7.58 13.75 6 13.75H4Z"
        fill="currentColor"
      />
    </svg>
  );
}

StartedIcon.propTypes = {
  props: PropTypes.any,
};
