import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as AppRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Main from "../layout/main";
import CardActivation from "../layout/card";
import { RequireCardAuth } from "./CardAuthWrapper";
import { CardLoginSignupWrapper } from "./CardLoginSignupWrapper";
import FallBack from "./FallBack";
import RedirectToValidateProduct from "../screens/landingpage/validateproduct/Redirect";
import { StoreItemContext } from "../screens/landingpage/productStore/components/storeItem/StoreContext";
import Product from "../screens/landingpage/productStore/productDisplay";

// const Home = lazy(() => import("../screens/landingpage/home"));
const Wallet = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/wallet"
  )
);
const Technology = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/technology"
  )
);
const Motivation = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/motivation"
  )
);
// const Card = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/products/card"
//   )
// );
// Store Links
const Store = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore"
  )
);
// const StoreEarbuds = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/productStore/earbud"
//   )
// );
// const StoreKeyFinder = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/productStore/keyFinder"
//   )
// );
// const StoreCharger = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/productStore/chagers"
//   )
// );
// const StoreOnyxPanther = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/productStore/onyxPanther"
//   )
// );
// const StoreSmartcard = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/productStore/smartCard"
//   )
// );
const StoreCart = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore/Cart"
  )
);

const WalletPage = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/walletPage"
  )
);
// const CardPage = lazy(() =>
//   import(
//     /* webpackPrefetch: true */
//     "../screens/landingpage/cardPage"
//   )
// );

//End of Store Links

//Buds page
const Buds = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/Buds"
  )
);
//main page
const MainPage = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/mainPage"
  )
);

const AccountDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore/accountDetails"
  )
);

// const EarBud = lazy(() => import("../screens/landingpage/products/earbud"));
const OnyxPanther = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/products/onyxpanther"
  )
);
const PillRing = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/products/pillring"
  )
);
const DigitalBusCard = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/walletcard/DigitalBusinessCard"
  )
);

// const ValidateProduct = lazy(() =>
//   import("../screens/landingpage/validateproduct")
// );
const Pill = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/products/pill"
  )
);
// const ValidateProduct = lazy(() =>
//   import("../screens/landingpage/validateproduct")
// );

const Shipping = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/shipping"
  )
);
const Cart = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/cart"
  )
);
const PaymentMethod = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/paymentmethod"
  )
);
const PrivacyPolicy = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/privacypolicy"
  )
);
// Card Profile for public view
const CardProfile = lazy(
  /* webpackPrefetch: true */
  () => import("../screens/cardprofile")
);

const GettingStarted = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/gettingstarted"
  )
);

const LoginCard = lazy(
  /* webpackPrefetch: true */
  () => import("../screens/dashboard/activatecard/login")
);

const ResetPassword = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/resetpassword"
  )
);

const VerifyEmail = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/verifyemail"
  )
);

const ProfilePicture = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/profilepicture"
  )
);

const ValidateCardToken = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/validatecardtoken"
  )
);

const AboutMe = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/addlinks"
  )
);

const SuccessfulActivation = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/success"
  )
);

const Connections = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/connections"
  )
);

const UpdatePassword = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/updatepassword"
  )
);

const Security = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/security"
  )
);

const Router = () => {
  return (
    <AppRouter>
      <StoreItemContext>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route
              index
              element={
                <Suspense fallback={<FallBack />}>
                  <Wallet />
                </Suspense>
              }
            />
            <Route path="faq" element={<RedirectToValidateProduct />} />
            {/* <Route
            path="product/validate"
            element={
              <Suspense fallback={<FallBack />}>
                <ValidateProduct />
              </Suspense>
            }
          /> */}
            <Route
              path="products/wallet"
              element={
                <Suspense fallback={<FallBack />}>
                  <WalletPage />
                </Suspense>
              }
            />
            <Route
              path="products/onyxpanther"
              element={
                <Suspense fallback={<FallBack />}>
                  <OnyxPanther />
                </Suspense>
              }
            />
            <Route
              path="products/pillring"
              element={
                <Suspense fallback={<FallBack />}>
                  <PillRing />
                </Suspense>
              }
            />
            <Route
              path="products/pill"
              element={
                <Suspense fallback={<FallBack />}>
                  <Pill />
                </Suspense>
              }
            />
            <Route
              path="products/card/digital-business-card"
              element={
                <Suspense fallback={<FallBack />}>
                  <DigitalBusCard />
                </Suspense>
              }
            />
            {/* Store */}
            <Route
              path="products/store"
              element={
                <Suspense fallback={<FallBack />}>
                  <Store />
                </Suspense>
              }
            >
              <Route
                path=":id"
                element={
                  <Suspense fallback={<FallBack />}>
                    <Product />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="products/cart"
              element={
                <Suspense fallback={<FallBack />}>
                  <StoreCart />
                </Suspense>
              }
            />
            <Route
              path="products/buds"
              element={
                <Suspense fallback={<FallBack />}>
                  <Buds />
                </Suspense>
              }
            />
            <Route
              path="products/cart/account-details"
              element={
                <Suspense fallback={<FallBack />}>
                  <AccountDetails />
                </Suspense>
              }
            />
            <Route
              path="Homepage"
              element={
                <Suspense fallback={<FallBack />}>
                  <MainPage />
                </Suspense>
              }
            />

            {/* Store ends */}
            <Route
              path="technology"
              element={
                <Suspense fallback={<FallBack />}>
                  <Technology />
                </Suspense>
              }
            />
            <Route
              path="motivation"
              element={
                <Suspense fallback={<FallBack />}>
                  <Motivation />
                </Suspense>
              }
            />
            <Route
              path="checkout/cart"
              element={
                <Suspense fallback={<FallBack />}>
                  <Cart />
                </Suspense>
              }
            />
            <Route
              path="checkout/shipping"
              element={
                <Suspense fallback={<FallBack />}>
                  <Shipping />
                </Suspense>
              }
            />
            <Route
              path="checkout/paymentmethod"
              element={
                <Suspense fallback={<FallBack />}>
                  <PaymentMethod />
                </Suspense>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <Suspense fallback={<FallBack />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>

          <Route
            path="/card"
            element={
              <Suspense fallback={null}>
                <CardProfile />
              </Suspense>
            }
          />

          <Route path="/activateaccount" element={<CardActivation />}>
            <Route
              index
              element={
                <CardLoginSignupWrapper>
                  <Suspense fallback={null}>
                    <GettingStarted />
                  </Suspense>
                </CardLoginSignupWrapper>
              }
            />

            <Route
              path="login"
              element={
                <CardLoginSignupWrapper>
                  <Suspense fallback={null}>
                    <LoginCard />
                  </Suspense>
                </CardLoginSignupWrapper>
              }
            />

            <Route
              path="resetpassword"
              element={
                <CardLoginSignupWrapper>
                  <Suspense fallback={null}>
                    <ResetPassword />
                  </Suspense>
                </CardLoginSignupWrapper>
              }
            />

            <Route
              path="verify-email"
              element={
                <Suspense fallback={null}>
                  <VerifyEmail />
                </Suspense>
              }
            />

            <Route
              path="updatepassword"
              element={
                <CardLoginSignupWrapper>
                  <Suspense fallback={null}>
                    <UpdatePassword />
                  </Suspense>
                </CardLoginSignupWrapper>
              }
            />

            <Route
              path="profilepicture"
              element={
                <RequireCardAuth>
                  <Suspense fallback={null}>
                    <ProfilePicture />
                  </Suspense>
                </RequireCardAuth>
              }
            />

            <Route
              path="validate-card"
              element={
                <RequireCardAuth>
                  <Suspense fallback={null}>
                    <ValidateCardToken />
                  </Suspense>
                </RequireCardAuth>
              }
            />

            <Route
              path="aboutme"
              element={
                <RequireCardAuth>
                  <Suspense fallback={null}>
                    <AboutMe />
                  </Suspense>
                </RequireCardAuth>
              }
            />

            <Route
              path="connections"
              element={
                <RequireCardAuth>
                  <Suspense fallback={null}>
                    <Connections />
                  </Suspense>
                </RequireCardAuth>
              }
            />

            <Route
              path="security"
              element={
                <RequireCardAuth>
                  <Suspense fallback={null}>
                    <Security />
                  </Suspense>
                </RequireCardAuth>
              }
            />

            <Route
              path="validate-card/success"
              element={
                <Suspense fallback={null}>
                  <SuccessfulActivation />
                </Suspense>
              }
            />

            <Route
              path="*"
              element={<Navigate to="/activateaccount" replace />}
            />
          </Route>
        </Routes>
      </StoreItemContext>
    </AppRouter>
  );
};

export default Router;
