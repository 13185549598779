import React from "react";
import styled from "styled-components";
import { data } from "./data";
import instagram from "../../../assets/icons/instagram.svg";
import twitter from "../../../assets/icons/twitter.svg";
import { LandingPagePadding } from "../../../common/styles/Paddings";
import Newsletter from "./component/newsletter";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <>
      {!pathname.includes("account-details") && (
        <Container className="footer">
          <Content>
            <Newsletter />
            <>
              {data.map((obj) => {
                return (
                  <div key={obj.title} className="">
                    <h6 className="!font-light">{obj.title}</h6>
                    <ul className="!opacity-70 !text-xs !font-extralight">
                      {obj.links.map((val) => (
                        <li key={val.title}>{val.title}</li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </>
          </Content>
          <Credit>
            <div className="footer-icon__container">
              {" "}
              <a
                href="https://twitter.com/officialaxao?s=21&t=xT_0YKnr18WFD9oQFbIXoQ"
                className="social-media__icon-link"
                rel="noreferrer"
                target="_blank"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href="https://instagram.com/officialaxao?igshid=MmJiY2I4NDBkZg=="
                className="social-media__icon-link"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                <img src={instagram} alt="" />{" "}
              </a>
            </div>
            <p className="max-md:mt-12">
              Copyright © {new Date().getFullYear()} AXAO all rights reserved.
            </p>
          </Credit>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 1380px;
  max-width: 100%;
  margin-top: 100px;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    min-width: 135px;
    margin-top: 79px;

    h6 {
      font-weight: 300;
      font-size: 1.0625;
      line-height: 107.3%;
      letter-spacing: -0.025em;
      text-transform: capitalize;
      color: var(--whiteColor);
    }

    ul {
      margin-top: 40px;
      li {
        // font-weight: 500;
        font-size: 0.9375rem;
        line-height: 190%;
        letter-spacing: -0.025em;
        text-transform: capitalize;
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }

  /* @media screen and (min-width: 768px) {
 
    div:not(:last-child)  {
      margin-right: 100px;
    }
    
  } */
`;

const Credit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 190%;
  letter-spacing: -0.065em;
  color: rgba(255, 255, 255, 0.5);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .footer-icon__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .social-media__icon-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    .footer-icon__container {
      margin-top: 30px;
    }
  }
`;

export default Footer;
