import React from "react";
import PropTypes from "prop-types";
import { ArrowLeft } from "../images/ArrowLeft";

export const StoreItemImage = ({
  item,
  color,
  selectedColor,
  setState,
  handlePreviousImage,
  handleNextImage,
}) => {

  return (
    <div className="storeItem-image__container !relative !flex !justify-center">
      <div className="overflow-hidden transition-transform w-[350px] h-[257px] flex items-center rounded-2xl">
        <img
          src={item.images}
          alt={item.name}
          loading="lazy"
          className="w-full object-cover"
        />
      </div>

      {/* Sliders */}
      <div className="absolute bottom-72 max-lg:-top-14 w-10/12 max-sm:w-full bg-transparent">
        <div className="flex flex-col justify-center items-center gap-3 w-full absolute max-sm:-top-[20px] max-lg:-top-40 bottom-0">
          <p className="font-thin text-[14px] text-white">Available Colors</p>
          <div className="flex gap-2 items-center transition-all">
            {color.map((val, i) => (
              <span
                key={i}
                style={{ backgroundColor: val}}
                className={`size-5 border-[2px] transition-all border-solid rounded-full ${
                  selectedColor === i ? "border-white" : "border-transparent"
                }`}
                onClick={() => setState(prev => ({...prev, selectedColor:i}))}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between w-full">
          <button
            className={`z-[999] disabled:cursor-not-allowed w-max transition-opacity ${
              selectedColor === 0 ? "opacity-30" : "opacity-100"
            }`}
            disabled={selectedColor === 0}
            onClick={handlePreviousImage}
          >
            <ArrowLeft />
          </button>
          <button
            className={`w-max !-rotate-180 disabled:cursor-not-allowed transition-opacity ${
              selectedColor === color.length - 1
                ? "opacity-30"
                : "opacity-100"
            }`}
            disabled={selectedColor === color.length - 1}
            onClick={handleNextImage}
          >
            <ArrowLeft />
          </button>
        </div>
      </div>
    </div>
  );
};

StoreItemImage.propTypes = {
  item: PropTypes.object,
  color:PropTypes.array,
  selectedColor: PropTypes.any,
  handlePreviousImage: PropTypes.func,
  setState:PropTypes.func,
  handleNextImage: PropTypes.func,
};
