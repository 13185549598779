import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const CardLoginSignupWrapper = ({ children }) => {
  const location = useLocation();
  let from = location.state?.from?.pathname;

  const authenticated = useSelector((state) => state.auth.isLoggedIn);

  if (authenticated) return <Navigate to={from && from !== "/activateaccount/validate-card" ? from : "/activateaccount/profilepicture"} replace />;

  return children;
};

CardLoginSignupWrapper.propTypes = {
  children: PropTypes.element,
};
