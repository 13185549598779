import React from "react";
import PropTypes from "prop-types";

export const LogoutIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity='0.8'
        d="M4.73751 1.26393H9.1137C9.35377 1.264 9.58485 1.35519 9.76027 1.51908C9.93568 1.68297 10.0423 1.90734 10.0587 2.14685C10.0751 2.38635 9.99991 2.62314 9.84842 2.80936C9.69692 2.99559 9.48038 3.11736 9.24256 3.15009L9.1137 3.15893H4.73751C4.01725 3.15884 3.32381 3.43218 2.79734 3.92371C2.27086 4.41524 1.95062 5.08831 1.90132 5.80688L1.895 6.00143V18.0031C1.89491 18.7234 2.16826 19.4168 2.65979 19.9433C3.15132 20.4698 3.82438 20.79 4.54295 20.8393L4.73751 20.8456H16.7392C17.4595 20.8457 18.1529 20.5724 18.6794 20.0808C19.2058 19.5893 19.5261 18.9162 19.5754 18.1977L19.5817 18.0031V17.374C19.5796 17.1323 19.6699 16.899 19.8341 16.7217C19.9984 16.5445 20.2241 16.4367 20.4653 16.4204C20.7064 16.4041 20.9446 16.4805 21.1312 16.6341C21.3178 16.7876 21.4387 17.0066 21.4691 17.2464L21.4767 17.374V18.0031C21.4768 19.2159 21.0118 20.3826 20.1774 21.2628C19.3431 22.1429 18.2029 22.6696 16.9919 22.7343L16.7392 22.7406H4.73751C3.52471 22.7407 2.35805 22.2757 1.47787 21.4414C0.597681 20.607 0.0709994 19.4669 0.00631684 18.2558L1.91691e-08 18.0031V6.00143C-0.000109013 4.78864 0.46491 3.62198 1.29927 2.74179C2.13362 1.86161 3.27377 1.33492 4.48484 1.27024L4.73751 1.26393H9.1137H4.73751ZM14.5321 4.47785V0.948091C14.532 0.773809 14.58 0.602873 14.6708 0.454087C14.7615 0.3053 14.8916 0.184429 15.0466 0.104768C15.2016 0.0251077 15.3756 -0.0102553 15.5494 0.00256899C15.7232 0.0153933 15.8901 0.0759082 16.0317 0.177457L16.1353 0.264627L23.7078 7.52881C24.0615 7.86738 24.0943 8.41314 23.805 8.78835L23.7078 8.89574L16.1353 16.1624C16.0097 16.2832 15.8531 16.3669 15.6829 16.4045C15.5127 16.442 15.3355 16.4319 15.1707 16.3752C15.0059 16.3185 14.8599 16.2175 14.7488 16.0832C14.6377 15.949 14.5658 15.7867 14.541 15.6142L14.5321 15.479V11.9391L14.1266 11.9315C10.7687 11.9214 7.89837 13.2997 5.45382 16.1018C4.82721 16.8194 3.65357 16.2686 3.8039 15.3286C4.84868 8.8351 8.35191 5.16511 14.1506 4.51575L14.5321 4.47785V0.948091V4.47785Z"
        fill="currentColor"
      />
    </svg>
  );
};

LogoutIcon.propTypes = {
  props: PropTypes.any,
};
