import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import heartIcon from "../../images/heart.svg";
import cartIcon from "../../../../../assets/icons/cartIcon.svg";
import "../../store.css";
import PropTypes from "prop-types";
import { filteredProducts } from "../../../../../common/components/product/allProduct";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Image } from "../../../../../common/components/product/ImageKit";

export default function StoreProducts({ isLandingPage }) {
  const country = localStorage.getItem("country");
  const data = filteredProducts;
  const { pathname } = useLocation();
  const scrollContainerRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollContainerRef;
    if (current) {
      const scrollAmount = 50;
      if (direction === "left") {
        current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {/* Store products */}
      <section className="relative w-full h-full !-mt-10 min-h-max store__container">
        <button
          className="absolute store__btn shadow-sm left-0 top-1/2 transform -translate-y-1/2 z-10  h-4/5  w-12 rounded-l-[20px] bg-black/0 hover:bg-black/5 focus:outline-none flex justify-center items-center"
          onClick={() => scroll("left")}
        >
          <IoIosArrowBack className="text-white/50 shadow-black" />
        </button>
        {/* <div className=" gap-2 min-h-[200px] overflow-x-auto scroll-smooth"/> */}
        <div
          className={`w-full ${pathname=== '/products/store'? 'grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-[20px] md:gap-y-[80px] xl:gap-y-[104px] gap-x-[10px] md:gap-x-[35px] xl:gap-x-[80px]':'flex space-x-4 max-md:space-x-5 scrollable-container'} overflow-x-auto scroll-smooth mt-12 pb-12 gap-2`}
          ref={scrollContainerRef}
        >
          {/* Earbud */}
          {data &&
            data.map((product) => {
              const path = !product.sold
                ? `${product.path}/${product.name.replaceAll(" ", "_")}`
                : "";
              return (
                <Link
                  to={path}
                  key={product.id}
                  className={`w-full min-w-[300px] max-md:min-w-[200px] max-sm:min-w-[150px] bg-gradient-to-t from-[#000] from-10% via-[#0B0B0B] via-55% max-md:via-65% to-[#101010] rounded-[20px] overflow-hidden relative p-[12px] h-auto last:mr-20 ${
                    product.sold ? "cursor-not-allowed" : "store-me  cursor-pointer"
                  }`}
                  // product.name === "Bold Pro" && "hidden"

                  aria-disabled={product.name === "Bold Pro"}
                >
                  {product.new && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      New
                    </div>
                  )}
                  {product.hot && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      Hot🔥
                    </div>
                  )}
                  {product.sold && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      Sold Out
                    </div>
                  )}
                  {product.preorder && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E]/50 z-10">
                      Pre-Order
                    </div>
                  )}
                  <div className="rounded-lg text-xs text-slate-200 absolute top-5 right-5 z-10">
                    <img src={heartIcon} alt="" />
                  </div>
                  <figure className="w-full object-cover">
                    <Image
                      path={product.ref}
                      alt={product.name}
                      loading="lazy"
                      className="opacity-80 z-0 w-full object-cover rounded-lg max-sm:h-[125px] xl:h-[250px] h-[cal(200px-5%)]"
                    />
                    <figcaption className="px-2">
                      <span className="block text-xl max-md:text-lg my-3 font-extralight tracking-tight text-[#D7D7D7]">
                        {product.name}
                      </span>

                      {/* Price */}
                      <span className="mt-7 mb-5 max-md:mb-3 bg-transparent border-[.1px] border-solid border-white/30 px-3 py-2 rounded-lg w-full overflow-x-hidden relative">
                        <img
                          src={cartIcon}
                          alt="cartIcon"
                          className="absolute left-3 top-1.5 transition-all duration-1000 ease-in-out opacity-0"
                        />
                        <span className="price font-extralight tracking-tight transition-all duration-1000 ease-in-out">
                          {country === "Nigeria" ? (
                            `₦${product.price.toLocaleString()}`
                          ) : (
                            <span>${product.foreign_price}</span>
                          )}
                        </span>
                      </span>
                    </figcaption>
                  </figure>
                </Link>
              );
            })}
        </div>

        <button
          className="absolute store__btn right-0 top-1/2 transform -translate-y-1/2 z-10 h-4/5  w-12 rounded-r-[20px] bg-black/0 hover:bg-black/5 focus:outline-none flex justify-center items-center"
          onClick={() => scroll("right")}
        >
          <IoIosArrowForward />
        </button>
      </section>
      {/* Axoa store products ends */}
    </>
  );
}

StoreProducts.propTypes = {
  isLandingPage: PropTypes.bool,
};

// import React, { useRef } from "react";
// import { Link } from "react-router-dom";
// import heartIcon from "../../images/heart.svg";
// import cartIcon from "../../../../../assets/icons/cartIcon.svg";
// import "../../store.css";
// import PropTypes from "prop-types";
// import { filteredProducts } from "../../../../../common/components/product/allProduct";

// export default function StoreProducts({ isLandingPage }) {
//   const country = localStorage.getItem("country");
//   const data = filteredProducts;
//   const scrollContainerRef = useRef(null);

//   const scroll = (direction) => {
//     const { current } = scrollContainerRef;
//     if (current) {
//       const scrollAmount = 350; // Adjust based on the scroll amount you want
//       if (direction === "left") {
//         current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
//       } else {
//         current.scrollBy({ left: scrollAmount, behavior: "smooth" });
//       }
//     }
//   };

//   return (
//     <>
//       {/* Store products */}
//       <section className="relative store-container responsive-design h-full overflow-x-hidden">

//       </section>
//       {/* Axoa store products ends */}
//     </>
//   );
// }

// StoreProducts.propTypes = {
//   isLandingPage: PropTypes.bool,
// };
