import React from "react";
import PropTypes from "prop-types";

export const LinkIcon = (props) => {
  return (
    <svg
    {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M14.988 17.5H16.498C19.518 17.5 21.998 15.03 21.998 12C21.998 8.98 19.528 6.5 16.498 6.5H14.988"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.37 10.012C2.13 10.632 2 11.302 2 12.002C2 15.022 4.47 17.502 7.5 17.502H9"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.99993 6.5H7.49993C6.66993 6.5 5.88993 6.68 5.17993 7.01"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12H16"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

LinkIcon.propTypes = {
  props: PropTypes.any,
};
