import React from "react";
import PropTypes from "prop-types";

export function SecurityIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M3.09009 6.72995C3.09009 5.90995 3.71009 4.97995 4.48009 4.66995L10.0501 2.38995C11.3001 1.87995 12.7101 1.87995 13.9601 2.38995L19.5301 4.66995C20.2901 4.97995 20.9201 5.90995 20.9201 6.72995V11.1199C20.9201 16.0099 17.3701 20.5899 12.5201 21.9299C12.1901 22.0199 11.8301 22.0199 11.5001 21.9299C6.65009 20.5899 3.10009 16.0099 3.10009 11.1199"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12.5C12.5304 12.5 13.0391 12.2893 13.4142 11.9142C13.7893 11.5391 14 11.0304 14 10.5C14 9.96957 13.7893 9.46086 13.4142 9.08579C13.0391 8.71071 12.5304 8.5 12 8.5C11.4696 8.5 10.9609 8.71071 10.5858 9.08579C10.2107 9.46086 10 9.96957 10 10.5C10 11.0304 10.2107 11.5391 10.5858 11.9142C10.9609 12.2893 11.4696 12.5 12 12.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12.5V15.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

SecurityIcon.propTypes = {
  props: PropTypes.any,
};
