import React from "react";
import "../dropdown/dropdown.css";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import bud from "../../../assets/img/BudCase.svg";

export default function NavDropdown({ closeDropdown, setCloseDropdown }) {
  const handleBudsNavigate = () => {
    setCloseDropdown(true);
  };
  const navigate = useNavigate()

  return (
    <div>
      <div className={`nav-dropdown`}>
        {/* BG-cover */}
        <div className="black-color"></div>

        <section className="nav-dropdown__texts">
          <div className="cursor-pointer" onClick={() => navigate('/products/store/onyxPanther')}>
            <img src={bud} alt="onyx panther" className="w-10/12"/>
          </div>
          {/* All products */}
          <div className="nav-dropdown__allProducts">
            <h2 className="nav-dropdown__heading">Explore All Products</h2>
            <NavLink to="/products/buds">
              <p
                className="nav-dropdown__tab onyxy--tab"
                onClick={handleBudsNavigate}
              >
                {" "}
                Onyx Panther
              </p>
            </NavLink>
            <p className="nav-dropdown__tab charger--tab">Black Charger</p>
            <p className="nav-dropdown__tab charger--tab">Bold Pro</p>
            <p className="nav-dropdown__tab charger--tab">Cards</p>
            <p className="nav-dropdown__tab charger--tab">Cables</p>
          </div>
          {/* More on Axoa */}
          <div className="nav-dropdown__allProducts">
            <h2 className="nav-dropdown__heading">More about AXAO</h2>
            <p className="nav-dropdown__tab onyxy--tab"> Core values</p>
            <p className="nav-dropdown__tab onyxy--tab"> Subscription plans</p>
            <p className="nav-dropdown__tab charger--tab">Meet the team</p>
          </div>
          {/* New Product */}
          <div className="nav-dropdown__allProducts">
            <h2 className="nav-dropdown__heading">New Product <span className="p-1 bg-blue-500 rounded font-normal text-xs text-white">New</span></h2>
            <p className="nav-dropdown__tab onyxy--tab">Product 1</p>
            <p className="nav-dropdown__tab charger--tab">Product 2</p>
            <p className="nav-dropdown__tab charger--tab">Meet the team</p>
          </div>
        </section>
      </div>
    </div>
  );
}

NavDropdown.propTypes = {
  closeDropdown: PropTypes.bool.isRequired,
  setCloseDropdown: PropTypes.func.isRequired,
};
