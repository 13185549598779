import React from "react";
import PropTypes from "prop-types";
import { IKContext, IKImage } from "imagekitio-react";

const urlEndpoint = "https://ik.imagekit.io/Adetimmy/";
const transform = [
  {
    height: "200",
    width: "200",
  },
];

export const Image = (props) => {
  return (
    <IKContext
      publicKey="public_YgXrMRmKMci3MDsWuZd/El7ephs="
      urlEndpoint={urlEndpoint}
    >
      <IKImage {...props} lqip={[{ active: true}]}/>
    </IKContext>
  );
};

Image.propTypes = {
  props: PropTypes.any,
};
